<template lang="pug">
div.boxSectionHeaderAndBody
  div#navtop(ref="navBarRef" :class="{'anchoEscritorioSubnavCerrado': subNavClosed , 'noTree': $route.meta.isGP}")
    div.sectionmenu
      div.firstlevel
        router-link(:to="{name: 'DeliveryReviewsReviews', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Read and Reply Opinions]
        router-link(v-if="$config.featureConfig.feedbackKeywords" :to="{name: 'DeliveryReviewsKeywords', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Keywords]
        router-link(v-if="$store.getters.getHasDishKeywords" :to="{name: 'DeliveryReviewsDish', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Platos]
        select-country-and-logout     

  div.gridSection
    div.sectionmenu
      filters-and-bread-crumb(:paramCOD="paramCOD" :isDelivery="true" ref="filtersRef")
      warning-not-delivery-not-internet(v-if="itemNotHasDelivery" type="delivery")
      router-view.bg-gray

</template>

<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import WarningNotDeliveryNotInternet from '@/components/common/WarningNotDeliveryNotInternet.vue'
import FiltersAndBreadCrumb from "@/components/filters/filtersAndBreadCrumb.vue"
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"
export default {
  props: ['paramCOD', 'type', 'site', 'typeRestaurant', 'after', 'before'],
  mixins: [commonMixins],
  data() {
    return {
      OptionTypeSelect: ''
    }
  },
  components: { FiltersAndBreadCrumb, WarningNotDeliveryNotInternet, SelectCountryAndLogout },
  async created() {
    this.$store.dispatch('getAllSitesOpinions')
    this.$store.commit(types.UPDATE_SECTION, 9)
    this.loadUrlParams()
  },
  mounted() {
    const height = this.$refs.navBarRef.clientHeight + this.$refs.filtersRef.containerHeight
    this.$store.commit(types.COMMON_TOP_SECTION_HEIGHT, height)
  },
  computed: {
    hasDishRanking() {
      return this.$store.getters.getUserInfo?.config['has_dish_ranking']
    }
  }
}
</script>

<style lang="scss" scoped>
#satisfactionhome {
  background-color: #F2F2F2;
  height: 100%;
  border-top-left-radius: 30px;
}

.sectionmenu {
  .thirstlevel {
    background-color: #F2F2F2;
    margin-top: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
</style>
